<!-- src/App.vue -->
<template>
  <div id="app">
    <ProcessPage />
  </div>
</template>

<script>
import ProcessPage from '@/components/ProcessPage.vue';

export default {
  components: {
    ProcessPage,
  },
};
</script>

<style lang="scss">

@import "@/styles/app.scss";

</style>
