import { createApp } from 'vue'
import App from './App.vue'

// import Amplify from 'aws-amplify';
// import awsconfig from './aws-exports';

// Amplify.configure(awsconfig);

// Now you can access environment variables
// console.log(awsconfig);

// Import Axios in your Vue component or Vuex store
// import axios from 'axios';

// Make a GET request to your backend
// axios.get('http://localhost:3000/')
//   .then(response => {
//     console.log(response.data);
//   })
//   .catch(error => {
//     console.error('Error fetching data from the backend:', error);
//   });

createApp(App).mount('#app')
